:root {
    --background: #121621;
  }
  
  .loader {
    --duration: 3s;
    --primary: rgba(28, 29, 37, 1);
    --primary-light: rgba(28, 29, 37, 0.5);
    --primary-rgba: rgba(28, 29, 37, 0);
    width: 200px;
    height: 320px;
    position: relative;
    transform-style: preserve-3d;
  }
  @media (max-width: 480px) {
    .loader {
      zoom: 0.44;
    }
  }
  .loader:before, .loader:after {
    --r: 20.5deg;
    content: "";
    width: 320px;
    height: 140px;
    position: absolute;
    right: 32%;
    bottom: -11px;
    background: var(--background);
    transform: translateZ(200px) rotate(var(--r));
    -webkit-animation: mask var(--duration) linear forwards infinite;
            animation: mask var(--duration) linear forwards infinite;
  }
  .loader:after {
    --r: -20.5deg;
    right: auto;
    left: 32%;
  }
  .loader .ground {
    position: absolute;
    left: -50px;
    bottom: -120px;
    transform-style: preserve-3d;
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
  }
  .loader .ground div {
    transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
    width: 200px;
    height: 200px;
    background: var(--primary);
    background: linear-gradient(45deg, var(--primary) 0%, var(--primary) 50%, var(--primary-light) 50%, var(--primary-light) 100%);
    transform-style: preserve-3d;
    -webkit-animation: ground var(--duration) linear forwards infinite;
            animation: ground var(--duration) linear forwards infinite;
  }
  .loader .ground div:before, .loader .ground div:after {
    --rx: 90deg;
    --ry: 0deg;
    --x: 44px;
    --y: 162px;
    --z: -50px;
    content: "";
    width: 156px;
    height: 300px;
    opacity: 0;
    background: linear-gradient(var(--primary), var(--primary-rgba));
    position: absolute;
    transform: rotateX(var(--rx)) rotateY(var(--ry)) translate(var(--x), var(--y)) translateZ(var(--z));
    -webkit-animation: ground-shine var(--duration) linear forwards infinite;
            animation: ground-shine var(--duration) linear forwards infinite;
  }
  .loader .ground div:after {
    --rx: 90deg;
    --ry: 90deg;
    --x: 0;
    --y: 177px;
    --z: 150px;
  }
  .loader .box {
    --x: 0;
    --y: 0;
    position: absolute;
    -webkit-animation: var(--duration) linear forwards infinite;
            animation: var(--duration) linear forwards infinite;
    transform: translate(var(--x), var(--y));
  }
  .loader .box div {
    background-color: var(--primary);
    width: 48px;
    height: 48px;
    position: relative;
    transform-style: preserve-3d;
    -webkit-animation: var(--duration) ease forwards infinite;
            animation: var(--duration) ease forwards infinite;
    transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
  }
  .loader .box div:before, .loader .box div:after {
    --rx: 90deg;
    --ry: 0deg;
    --z: 24px;
    --y: -24px;
    --x: 0;
    content: "";
    position: absolute;
    background-color: inherit;
    width: inherit;
    height: inherit;
    transform: rotateX(var(--rx)) rotateY(var(--ry)) translate(var(--x), var(--y)) translateZ(var(--z));
    filter: brightness(var(--b, 1.2));
  }
  .loader .box div:after {
    --rx: 0deg;
    --ry: 90deg;
    --x: 24px;
    --y: 0;
    --b: 1.4;
  }
  .loader .box.box0 {
    --x: -220px;
    --y: -120px;
    left: 58px;
    top: 108px;
  }
  .loader .box.box1 {
    --x: -260px;
    --y: 120px;
    left: 25px;
    top: 120px;
  }
  .loader .box.box2 {
    --x: 120px;
    --y: -190px;
    left: 58px;
    top: 64px;
  }
  .loader .box.box3 {
    --x: 280px;
    --y: -40px;
    left: 91px;
    top: 120px;
  }
  .loader .box.box4 {
    --x: 60px;
    --y: 200px;
    left: 58px;
    top: 132px;
  }
  .loader .box.box5 {
    --x: -220px;
    --y: -120px;
    left: 25px;
    top: 76px;
  }
  .loader .box.box6 {
    --x: -260px;
    --y: 120px;
    left: 91px;
    top: 76px;
  }
  .loader .box.box7 {
    --x: -240px;
    --y: 200px;
    left: 58px;
    top: 87px;
  }
  .loader .box0 {
    -webkit-animation-name: box-move0;
            animation-name: box-move0;
  }
  .loader .box0 div {
    -webkit-animation-name: box-scale0;
            animation-name: box-scale0;
  }
  .loader .box1 {
    -webkit-animation-name: box-move1;
            animation-name: box-move1;
  }
  .loader .box1 div {
    -webkit-animation-name: box-scale1;
            animation-name: box-scale1;
  }
  .loader .box2 {
    -webkit-animation-name: box-move2;
            animation-name: box-move2;
  }
  .loader .box2 div {
    -webkit-animation-name: box-scale2;
            animation-name: box-scale2;
  }
  .loader .box3 {
    -webkit-animation-name: box-move3;
            animation-name: box-move3;
  }
  .loader .box3 div {
    -webkit-animation-name: box-scale3;
            animation-name: box-scale3;
  }
  .loader .box4 {
    -webkit-animation-name: box-move4;
            animation-name: box-move4;
  }
  .loader .box4 div {
    -webkit-animation-name: box-scale4;
            animation-name: box-scale4;
  }
  .loader .box5 {
    -webkit-animation-name: box-move5;
            animation-name: box-move5;
  }
  .loader .box5 div {
    -webkit-animation-name: box-scale5;
            animation-name: box-scale5;
  }
  .loader .box6 {
    -webkit-animation-name: box-move6;
            animation-name: box-move6;
  }
  .loader .box6 div {
    -webkit-animation-name: box-scale6;
            animation-name: box-scale6;
  }
  .loader .box7 {
    -webkit-animation-name: box-move7;
            animation-name: box-move7;
  }
  .loader .box7 div {
    -webkit-animation-name: box-scale7;
            animation-name: box-scale7;
  }
  
  @-webkit-keyframes box-move0 {
    12% {
      transform: translate(var(--x), var(--y));
    }
    25%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  
  @keyframes box-move0 {
    12% {
      transform: translate(var(--x), var(--y));
    }
    25%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @-webkit-keyframes box-scale0 {
    6% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    14%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @keyframes box-scale0 {
    6% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    14%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @-webkit-keyframes box-move1 {
    16% {
      transform: translate(var(--x), var(--y));
    }
    29%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @keyframes box-move1 {
    16% {
      transform: translate(var(--x), var(--y));
    }
    29%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @-webkit-keyframes box-scale1 {
    10% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    18%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @keyframes box-scale1 {
    10% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    18%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @-webkit-keyframes box-move2 {
    20% {
      transform: translate(var(--x), var(--y));
    }
    33%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @keyframes box-move2 {
    20% {
      transform: translate(var(--x), var(--y));
    }
    33%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @-webkit-keyframes box-scale2 {
    14% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    22%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @keyframes box-scale2 {
    14% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    22%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @-webkit-keyframes box-move3 {
    24% {
      transform: translate(var(--x), var(--y));
    }
    37%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @keyframes box-move3 {
    24% {
      transform: translate(var(--x), var(--y));
    }
    37%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @-webkit-keyframes box-scale3 {
    18% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    26%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @keyframes box-scale3 {
    18% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    26%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @-webkit-keyframes box-move4 {
    28% {
      transform: translate(var(--x), var(--y));
    }
    41%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @keyframes box-move4 {
    28% {
      transform: translate(var(--x), var(--y));
    }
    41%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @-webkit-keyframes box-scale4 {
    22% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    30%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @keyframes box-scale4 {
    22% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    30%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @-webkit-keyframes box-move5 {
    32% {
      transform: translate(var(--x), var(--y));
    }
    45%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @keyframes box-move5 {
    32% {
      transform: translate(var(--x), var(--y));
    }
    45%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @-webkit-keyframes box-scale5 {
    26% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    34%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @keyframes box-scale5 {
    26% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    34%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @-webkit-keyframes box-move6 {
    36% {
      transform: translate(var(--x), var(--y));
    }
    49%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @keyframes box-move6 {
    36% {
      transform: translate(var(--x), var(--y));
    }
    49%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @-webkit-keyframes box-scale6 {
    30% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    38%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @keyframes box-scale6 {
    30% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    38%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @-webkit-keyframes box-move7 {
    40% {
      transform: translate(var(--x), var(--y));
    }
    53%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @keyframes box-move7 {
    40% {
      transform: translate(var(--x), var(--y));
    }
    53%, 52% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(0, -32px);
    }
    90%, 100% {
      transform: translate(0, 188px);
    }
  }
  @-webkit-keyframes box-scale7 {
    34% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    42%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @keyframes box-scale7 {
    34% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(0);
    }
    42%, 100% {
      transform: rotateY(-47deg) rotateX(-15deg) rotateZ(15deg) scale(1);
    }
  }
  @-webkit-keyframes ground {
    0%, 65% {
      transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
    }
    75%, 90% {
      transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(1);
    }
    100% {
      transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
    }
  }
  @keyframes ground {
    0%, 65% {
      transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
    }
    75%, 90% {
      transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(1);
    }
    100% {
      transform: rotateX(90deg) rotateY(0deg) translate(-48px, -120px) translateZ(100px) scale(0);
    }
  }
  @-webkit-keyframes ground-shine {
    0%, 70% {
      opacity: 0;
    }
    75%, 87% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes ground-shine {
    0%, 70% {
      opacity: 0;
    }
    75%, 87% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes mask {
    0%, 65% {
      opacity: 0;
    }
    66%, 100% {
      opacity: 1;
    }
  }
  @keyframes mask {
    0%, 65% {
      opacity: 0;
    }
    66%, 100% {
      opacity: 1;
    }
  }
  body {
    background: var(--background);
  }
  
  small {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 50%;
    font-family: Arial;
    max-width: 320px;
    font-size: 12px;
    line-height: 19px;
    z-index: 1;
    color: #6C7486;
  }
  @media (min-width: 481px) {
    small {
      transform: translateX(-50%);
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    small {
      left: 20px;
      right: 112px;
      font-size: 10px;
      line-height: 17px;
      transform: translateZ(400px);
    }
  }
  
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  
  * {
    box-sizing: inherit;
  }
  *:before, *:after {
    box-sizing: inherit;
  }

  .loader-static {
    --background: linear-gradient(135deg, rgba(28, 29, 37, 0.7), rgba(28, 29, 37, 0.7));
    --shadow: rgba(39, 94, 254, 0.28);
    --text: #6C7486;
    --page: rgba(255, 255, 255, 0.12);
    --page-fold: rgba(255, 255, 255, 0.24);
    --duration: 3s;
    width: 200px;
    height: 140px;
    position: relative;
  }
  .loader-static:before, .loader-static:after {
    --r: -6deg;
    content: "";
    position: absolute;
    bottom: 8px;
    width: 120px;
    top: 80%;
    box-shadow: 0 16px 12px var(--shadow);
    transform: rotate(var(--r));
  }
  .loader-static:before {
    left: 4px;
  }
  .loader-static:after {
    --r: 6deg;
    right: 4px;
  }
  .loader-static div {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    position: relative;
    z-index: 1;
    perspective: 600px;
    box-shadow: 0 4px 6px var(--shadow);
    background-image: var(--background);
  }
  .loader-static div ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
  }
  .loader-static div ul li {
    --r: 180deg;
    --o: 0;
    --c: var(--page);
    position: absolute;
    top: 10px;
    left: 10px;
    transform-origin: 100% 50%;
    color: var(--c);
    opacity: var(--o);
    transform: rotateY(var(--r));
    -webkit-animation: var(--duration) ease infinite;
            animation: var(--duration) ease infinite;
  }
  .loader-static div ul li:nth-child(2) {
    --c: var(--page-fold);
    -webkit-animation-name: page-2;
            animation-name: page-2;
  }
  .loader-static div ul li:nth-child(3) {
    --c: var(--page-fold);
    -webkit-animation-name: page-3;
            animation-name: page-3;
  }
  .loader-static div ul li:nth-child(4) {
    --c: var(--page-fold);
    -webkit-animation-name: page-4;
            animation-name: page-4;
  }
  .loader-static div ul li:nth-child(5) {
    --c: var(--page-fold);
    -webkit-animation-name: page-5;
            animation-name: page-5;
  }
  .loader-static div ul li svg {
    width: 90px;
    height: 120px;
    display: block;
  }
  .loader-static div ul li:first-child {
    --r: 0deg;
    --o: 1;
  }
  .loader-static div ul li:last-child {
    --o: 1;
  }
  .loader-static span {
    display: block;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 20px;
    text-align: center;
    color: var(--text);
  }
  
  @-webkit-keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%, 100% {
      opacity: 0;
    }
    50%, 100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    35%, 100% {
      opacity: 0;
    }
    50%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
    65%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
    65%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%, 100% {
      opacity: 0;
    }
    80%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    65%, 100% {
      opacity: 0;
    }
    80%, 100% {
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%, 100% {
      opacity: 0;
    }
    95%, 100% {
      transform: rotateY(0deg);
    }
  }
  @keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    80%, 100% {
      opacity: 0;
    }
    95%, 100% {
      transform: rotateY(0deg);
    }
  }
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  
  * {
    box-sizing: inherit;
  }
  *:before, *:after {
    box-sizing: inherit;
  }

  :root {
    --effect: hover 1s linear infinite;
 }
 * {
    margin: 0;
 }


 @import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");
.about {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.2s ease;
}
.about .bg_links {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  backdrop-filter: blur(5px);
  position: absolute;
}
.about .logo {
  width: 40px;
  height: 40px;
  z-index: 9;
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 10px 7px;
  opacity: 0.9;
  transition: all 1s 0.2s ease;
  bottom: 0;
  right: 0;
}
.about .social {
  opacity: 0;
  right: 0;
  bottom: 0;
}
.about .social .icon {
  width: 100%;
  height: 100%;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  display: flex;
  transition: all 0.2s ease, background-color 0.4s ease;
  opacity: 0;
  border-radius: 100%;
}
.about .social.portfolio {
  transition: all 0.8s ease;
}
.about .social.portfolio .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
}
.about .social.dribbble {
  transition: all 0.3s ease;
}
.about .social.dribbble .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
}
.about .social.linkedin {
  transition: all 0.8s ease;
}
.about .social.linkedin .icon {
  background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
}
.about:hover {
  width: 105px;
  height: 105px;
  transition: all 0.6s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .logo {
  opacity: 1;
  transition: all 0.6s ease;
}
.about:hover .social {
  opacity: 1;
}
.about:hover .social .icon {
  opacity: 0.9;
}
.about:hover .social:hover {
  background-size: 28px;
}
.about:hover .social:hover .icon {
  background-size: 65%;
  opacity: 1;
}
.about:hover .social.portfolio {
  right: 0;
  bottom: calc(100% - 40px);
  transition: all 0.3s 0s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.portfolio .icon:hover {
  background-color: #698fb7;
}
.about:hover .social.dribbble {
  bottom: 45%;
  right: 45%;
  transition: all 0.3s 0.15s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.dribbble .icon:hover {
  background-color: #ea4c89;
}
.about:hover .social.linkedin {
  bottom: 0;
  right: calc(100% - 40px);
  transition: all 0.3s 0.25s cubic-bezier(0.64, 0.01, 0.07, 1.65);
}
.about:hover .social.linkedin .icon:hover {
  background-color: #0077b5;
}

.text p {
  color: rgba(186, 203, 217, .25);
  font-size: 14px;
  position: absolute;
  font-family: "Roboto Mono", monospace;
  animation: text 4s ease infinite;
  width: 100px;
  text-align: center;
}

.text p.right {
  right: 40px;
}

.text p.left {
  left: 40px;
}

.text p.bottom {
  bottom: 40px;
}

.text p.top {
  top: 40px;
}

@keyframes text {
  0% {
    transform: translateX(-30px);
    letter-spacing: 0px;
    color: rgba(186, 203, 217, .15);
  }
  15% {
    letter-spacing: 3px;
    color: rgba(126, 161, 191, .15);;
  }
  50% {
    transform: translateX(30px);
    letter-spacing: 0px;
    color: rgba(186, 203, 217, .15);
  }
  75% {
    letter-spacing: 3px;
    color: rgba(126, 161, 191, .15);;
  }
  100% {
    transform: translateX(-30px);
    letter-spacing: 0px;
    color: rgba(186, 203, 217, .15);
  }
}