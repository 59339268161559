.swiper {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-pagination-bullet {
  background: white;
}

.swiper-pagination-bullet-active {
  background: white;
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 50px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
}

.swiper-slide {
  background-color: rgb(134, 134, 134);
}

/*.swiper-slide:nth-child(1n) {*/
/*    background-color: rgb(206, 17, 17);*/
/*}*/

/*.swiper-slide:nth-child(2n) {*/
/*    background-color: rgb(0, 140, 255);*/
/*}*/

/*.swiper-slide:nth-child(3n) {*/
/*    background-color: rgb(10, 184, 111);*/
/*}*/

/*.swiper-slide:nth-child(4n) {*/
/*    background-color: rgb(211, 122, 7);*/
/*}*/

/*.swiper-slide:nth-child(5n) {*/
/*    background-color: rgb(118, 163, 12);*/
/*}*/

/*.swiper-slide:nth-child(6n) {*/
/*    background-color: rgb(180, 10, 47);*/
/*}*/

/*.swiper-slide:nth-child(7n) {*/
/*    background-color: rgb(35, 99, 19);*/
/*}*/

/*.swiper-slide:nth-child(8n) {*/
/*    background-color: rgb(0, 68, 255);*/
/*}*/

/*.swiper-slide:nth-child(9n) {*/
/*    background-color: rgb(218, 12, 218);*/
/*}*/

/*.swiper-slide:nth-child(10n) {*/
/*    background-color: rgb(54, 94, 77);*/
/*}*/

.swiper-pagination-dating-item.swiper-pagination-bullet {
  position: relative;
  width: 12px;
  height: 12px;
}
.swiper-pagination-dating-item.swiper-pagination-bullet img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.swiper-pagination-dating-default {

}
