@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .bgHeight {
        height: 100vh;
        /*height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))*/
        height: calc(100vh - env(safe-area-inset-top) - 75px)
    }

    .MoveBodyHeight {
        height: 100vh;
        /*height: calc(100vh - 84px - env(safe-area-inset-top) - env(safe-area-inset-bottom))*/
        height: calc(100vh - 84px - env(safe-area-inset-top) - 75px)
    }
}

@supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    .bgHeight {
        height: 100vh;
    }

    .MoveBodyHeight {
        height: calc(100vh - 84px);
    }
}

/*.bgHeight {*/
/*    height: 100vh;*/
/*    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))*/
/*}*/

/*.MoveBodyHeight {*/
/*    height: 100vh;*/
/*    height: calc(100vh - 84px - env(safe-area-inset-top) - env(safe-area-inset-bottom))*/
/*}*/